import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import PromptBigQ from "./pages/Prompt";
import ImageGen from "./pages/ImageGen";
import Homepage from "./pages/Homepage";
import UrlPrompt from "./pages/UrlPrompt";
import ItineraryInsight from "./pages/ItineraryInsight";
import CsvPrompt from "./pages/CsvPrompt";
import FinancialPrompt from "./pages/FinancialAskQuestion/FinancialPrompt";
import VoiceSpeechUpload from "./pages/VoiceUpload/VoiceSpeechUploader";
import VideolPrompt from "./pages/FinancialAskQuestion/VideoPrompt";
import VideoRecognitionPrompt from "./pages/VideoRecognition/VideoRecognitionPrompt";
import PdfToCsv from "./pages/RPG/PdfToCsv";
import ABGPolicyPrompt from "./pages/ABGPolicyPrompt";
import ImageDescription from "./pages/ImageDescription";
import VideoDescription from "./pages/VideoContent/VideoDescription";
import SBILogin from "./pages/SBI/pages/Login";
import SBIChat from "./pages/SBI/pages/PolicySearch";
import MaricoEdibleOil from "./pages/Marico/MaricoEdibleOil";
import MaricoSales from "./pages/Marico/MaricoSales";
import JSWPrompts from "./pages/JSWCement/JSWPrompt";
import Ecommerce from "./pages/ecommerce/Ecommerce";
import Prompt from "./pages/tata-motors/src/pages/Prompt";
import TrendSearch from "./pages/titan/TrendSearch";
// import AudioStreaming from "./pages/AudioStreaming/AudioStreaming";
let mails = [
  "genaidemo@atgeirsolutions.com",
  "ashok_leyland_demo@atgeirsolutions.com",
  "dhanraj.jagtap@atgeirsolutions.com",
  "jagtapdhanraj116@outlook.com",
  "webinardemo@atgeirsolutions.com",
  "urvashi@atgeirsolutions.com",
];

const MainRoutes = () => {
  // const [user, setUser] = useState();

  // const getUserGoogle = async () => {
  //   const url = `http://localhost:8080/googleauth/login/success`;
  //   axios
  //     .get(url, { withCredentials: true })
  //     .then((response) => {
  //       if (response != undefined) {
  //         console.log("google user", response);
  //         sessionStorage.setItem(
  //           "authentication",
  //           JSON.stringify(response.data.user._json.email)
  //         );
  //         setUser(response.data.user._json);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const getUserMicrosoft = async () => {
  //   const url = `http://localhost:8080/microsoftauth/login/success`;
  //   axios
  //     .get(url, { withCredentials: true })
  //     .then((response) => {
  //       if (response != undefined) {
  //         console.log("microsoft user", response.data.user._json);
  //         sessionStorage.setItem(
  //           "authentication",
  //           JSON.stringify(response.data.user._json.mail)
  //         );
  //         setUser(response.data.user._json);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const getUserGithub = async () => {
  //   const url = `http://localhost:8080/githubauth/login/success`;
  //   axios
  //     .get(url, { withCredentials: true })
  //     .then((response) => {
  //       if (response != undefined) {
  //         console.log("github user", response.data.user._json);
  //         sessionStorage.setItem(
  //           "authentication",
  //           JSON.stringify(response.data.user._json.email)
  //         );
  //         setUser(response.data.user._json);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getUserGoogle();
  //   getUserMicrosoft();
  //   getUserGithub();
  // }, []);
  const ProtectedRoute = ({ redirectPath = "/", children }) => {
    let id = JSON.parse(sessionStorage.getItem("authentication"));
    if (!mails.includes(id)) {
      return <Navigate to={redirectPath} />;
    }
    return children;
  };
  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route
        path="/ask-bigquery"
        element={
          <ProtectedRoute>
            <PromptBigQ />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/image-creator"
        element={
          <ProtectedRoute>
            <ImageGen />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/ask-document"
        element={
          <ProtectedRoute>
            <UrlPrompt />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/home"
        element={
          // mails.includes(user?.email) ? (
          //   <Homepage />
          // ) : (
          <ProtectedRoute>
            <Homepage />
          </ProtectedRoute>
          // )
        }
      ></Route>
      <Route
        path="/itinerary-insight"
        element={
          <ProtectedRoute>
            <ItineraryInsight />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/ask-csv"
        element={
          <ProtectedRoute>
            <CsvPrompt />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/ask-finance"
        element={
          <ProtectedRoute>
            <FinancialPrompt />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/voice-summary"
        element={
          <ProtectedRoute>
            {/* <VoiceSpeechUpload /> */}
            <Prompt />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/ask-video"
        element={
          <ProtectedRoute>
            <VideolPrompt />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/ask-video-recognition"
        element={
          <ProtectedRoute>
            <VideoRecognitionPrompt />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/rpg"
        element={
          <ProtectedRoute>
            <PdfToCsv />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/policy-search"
        element={
          <ProtectedRoute>
            <ABGPolicyPrompt />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/marico-search"
        element={
          <ProtectedRoute>
            <MaricoEdibleOil />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/marico-sales"
        element={
          <ProtectedRoute>
            <MaricoSales />
            {/* <Sidebar /> */}
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/image-description"
        element={
          <ProtectedRoute>
            <ImageDescription />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/video-description"
        element={
          <ProtectedRoute>
            <VideoDescription />
          </ProtectedRoute>
        }
      ></Route>
      {/* <Route path="/chat-app" element={<SBILogin />}></Route> */}
      <Route
        path="/banking-assistant"
        element={
          <ProtectedRoute>
            <SBIChat />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/jsw-search"
        element={
          <ProtectedRoute>
            <JSWPrompts />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/e-commerce"
        element={
          <ProtectedRoute>
            <Ecommerce />
          </ProtectedRoute>
        }
      ></Route>
      {/* <Route
        path="/audio-streaming"
        element={
          <ProtectedRoute>
            <AudioStreaming />
          </ProtectedRoute>
        }
      ></Route> */}
      <Route
        path="/trends"
        element={
          <ProtectedRoute>
            <TrendSearch />
          </ProtectedRoute>
        }
      ></Route>
    </Routes>
  );
};

export default MainRoutes;

// {
//   "tabs": [
//     {
//       "id": 1,
//       "title": "Ask BigQuery",
//       "icon": {
//         "component": "BsQuestionDiamondFill",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Ask question on sales data in BigQuery database.",
//       "route": "/ask-bigquery",
//       "tags": ["database", "sales", "bigquery", "structured data"]
//     },
//     {
//       "id": 2,
//       "title": "Unstructured Data Q&A",
//       "icon": {
//         "component": "BsLink45Deg",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Ask Questions on PDF Documents and Website URLs.",
//       "route": "/ask-document",
//       "tags": ["unstructured data", "documents", "pdf", "upload"]
//     },

//ITC unstructured data
// {
//   "id": 2,
//   "title": "Unstructured Data Q&A",
//   "icon": {
//     "component": "BsLink45Deg",
//     "props": {
//       "className": "ag-icons",
//       "size": "1.3rem"
//     }
//   },
//   "description": "Ask Questions on PDF Documents.",
//   "route": "/ask-document",
//   "tags": ["unstructured data", "documents", "pdf", "upload"]
// },
//     {
//       "id": 3,
//       "title": "CSV Data Q&A",
//       "icon": {
//         "component": "BsFiletypeCsv",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.2rem"
//         }
//       },
//       "description": "Ask Questions on CSV Documents",
//       "route": "/ask-csv",
//       "tags": ["structured data", "documents", "csv", "upload"]
//     },
//     {
//       "id": 4,
//       "title": "Search",
//       "icon": {
//         "component": "BsSearch",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Search on Unstructured, Structured and Images data.",
//       "route": "/ask-finance",
//       "tags": ["unstructured data", "structured data", "images", "search"]
//     },
//     {
//       "id": 5,
//       "title": "Speech Summarization",
//       "icon": {
//         "component": "AiFillAudio",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Summarize the main takeaways from the audio.",
//       "route": "/voice-summary",
//       "tags": ["audio", "speech", "transcript"]
//     },
//     {
//       "id": 6,
//       "title": "Ask Questions From YouTube Video",
//       "icon": {
//         "component": "AiFillPlayCircle",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Summarize the main takeaways from the video",
//       "route": "/ask-video",
//       "tags": ["video", "youtube", "recognition"]
//     },
//     {
//       "id": 7,
//       "title": "Group Insurance Document Q&A",
//       "icon": {
//         "component": "IoDocumentText",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Ask Questions on Policy PDF Documents.",
//       "route": "/policy-search",
//       "tags": ["documents", "insurance", "pdf"]
//     },
//     {
//       "id": 8,
//       "title": "Itinerary Insight",
//       "icon": {
//         "component": "SiYourtraveldottv",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Ask questions to travel bot to get flight details and places.",
//       "route": "/itinerary-insight",
//       "tags": ["travel", "flight", "itinerary"]
//     },
//     {
//       "id": 9,
//       "title": "Video Intelligence",
//       "icon": {
//         "component": "AiFillPlayCircle",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Ask Questions on video and and Extract Relevant contents",
//       "route": "/ask-video-recognition",
//       "tags": ["video", "content extraction", "recognition"]
//     },
//     {
//       "id": 10,
//       "title": "Ask Questions From Image",
//       "icon": {
//         "component": "CiImageOn",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Upload a Image and ask questions on that image",
//       "route": "/image-description",
//       "tags": ["images", "upload", "recognition"]
//     },
//     {
//       "id": 11,
//       "title": "Generate Content From Video",
//       "icon": {
//         "component": "IoVideocamOutline",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Ask AI to generate content on videos stored in GCP Bucket.",
//       "route": "/video-description",
//       "tags": ["video", "content generation", "recognition"]
//     },
//     {
//       "id": 12,
//       "title": "Banking Assistant",
//       "icon": {
//         "component": "RiKakaoTalkFill",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "Talk with compassionate deceased management assistant.",
//       "route": "/chat-app",
//       "tags": ["banking", "assistant", "chat"]
//     },
//     {
//       "id": 13,
//       "title": "Trends",
//       "icon": {
//         "component": "FaArrowTrendUp",
//         "props": {
//           "className": "ag-icons",
//           "size": "1.3rem"
//         }
//       },
//       "description": "View and Search current Trends in Fashion",
//       "route": "/e-commerce",
//       "tags": ["trends", "fashion", "search"]
//     }
//   ]
// }
